export const dateToMysqlFormat = (d: Date) => {
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Ensure 2 digits for month
    const day = String(d.getDate()).padStart(2, '0'); // Ensure 2 digits for day

    return `${year}-${month}-${day}`;
}

export const datetimeToMysqlFormat = (d: Date) => {
    const str = d.toISOString().split('T'); 
    return `${str[0]} ${str[1].split('.')[0]}Z`; 
}

export const mysqlDateStrToDate = (dateStr: string) => {
    return new Date(dateStr.replace( /(\d{4})-(\d{2})-(\d{2})/, "$1/$2/$3"));
}

export const mysqlDatetimeStrToDate = (datetimeStr: string) => {
    return new Date(datetimeStr + " UTC");
}

export const hasDST = (date = new Date()) => {
    const january = new Date(date.getFullYear(), 0, 1).getTimezoneOffset();
    const july = new Date(date.getFullYear(), 6, 1).getTimezoneOffset();
  
    return Math.max(january, july) !== date.getTimezoneOffset();
}

export const addHours = (date: Date, hours: number): Date => {
    date.setTime(date.getTime() + (hours * 3600000));
    return date;
}